/* istanbul ignore file */
/* react-leaflet incompatible with testing library, tested in cypress! */

import * as React from "react";
import { Circle, LayerGroup, Map, Tooltip } from "react-leaflet";

import { DeviceClusterResponse } from "../../../services/core/maps";
import { useClusterProps } from "./utils";
import classNames from "classnames";


interface IClusterProps {
  lat: number,
  lng: number,
  count: number,
  radius: number,
  loading: boolean
}

export const Cluster = (props: IClusterProps) => {
  const { lat, lng, count, radius, loading } = props;

  return <Circle
    data-id="cluster"
    key={`${lat}${lng}`}
    center={{ lat, lng }}
    radius={radius}
    fillColor={'#71ACEF'}
    color={'#1979E6'}
    fillOpacity={0.5}
    className={classNames("cluster", { loading })}
    data-num={count}
  >
    <Tooltip
      permanent={true}
      offset={[0, 0]}
      direction="bottom"
      className={classNames("cluster-label", { loading })}
    >
      {count}
    </Tooltip>
  </Circle>;
};

interface OverlayLayerProps {
  mapRef: React.MutableRefObject<Map>,
  isLoading: boolean,
  data?: DeviceClusterResponse
}

export const OverlayLayer = ({ data, mapRef, isLoading }: OverlayLayerProps) => {
  const bounds = mapRef.current?.leafletElement?.getBounds();

  const clusterProps = useClusterProps(bounds, data);

  return <LayerGroup>
    {clusterProps.map((clusterProps) => <Cluster {...clusterProps} loading={isLoading} />)}
  </LayerGroup>;
};
