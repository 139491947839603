import { ITranslationKeys } from "./keys";

// istanbul ignore next
export const frTranslations: ITranslationKeys = {
  translation: {
    "AND": 'et',
    "BATTERY": "batterie",
    "BATTERY_other": "batteries",
    "BATTERY_WITH_COUNT": "{{count}} batterie",
    "BATTERY_WITH_COUNT_other": "{{count}} batteries",
    "DEVICE": "Appareil",
    "DEVICE_other": "Appareils",
    "DEVICES_LIST_TITLE": "Liste d'appareils",
    "GROUP": "Groupe",
    "GROUP_other": "Groupes",
    "GROUP_WITH_COUNT": "{{count}} groupe",
    "GROUP_WITH_COUNT_other": "{{count}} groupes",
    get NO_GROUP() { return `Aucun ${this.GROUP.toLowerCase()}`; },
    NO_GROUP_title: '$t(translation:NO_GROUP)',
    NO_HOME_LOCATION: "Aucun endroit d'origine",
    "BATTERIES_IN_GROUP": "$t(BATTERY_WITH_COUNT, {'count': {{batteries}} }) dans $t(GROUP_WITH_COUNT, {'count': {{groups}} })",
    "LICENSED": "Sous licence",
    "MAPS": "Cartes",
    "PURCHASE": "Achetez",
    "PRODUCT_SELECTION": "Sélection de produits",
    "ADMIN": "Administrateur",
    "OF": "de",
    "DOWNLOAD": "Télécharger",
    "ALL": "Tous",
    "NO_PROBLEMS": "Pas de problème",
    "HEALTH_WARNING": "Avertissement d'état",
    "POOR_HEALTH": "Mauvaise état",
    "CYCLE_COUNT_WARNING": "Avertissement du nombre de cycles",
    "HIGH_CYCLE_COUNT": "Nombre de cycles elevé",
    "LAST_TIME_SEEN_STATEMENT": "{{timePeriod, lowercase}}",
    "VIEW_IN_CORE": "Vue via Core",
    "VIEW_IN_SMART_SERVICE": "Vue via Smart Service",
    "FILTERS": "Filtres",
    "APPLY": "Appliquer",
    "CANCEL": "Annuler",
    "CONFIRM": "Confirmer",
    "DELETE": "Supprimer",
    "NEW": "Nouvelle",
    "FILTER": "Filtrer",
    "EDIT": "Éditer",
    "EXPORT": "Exporter",
    "SEARCH": "Chercher",
    "TO": "à",
    "INFORMATION": "Information",
    "CHARGE_LEVEL": "Niveau de charge",
    "FILTER_STATUS_TEXT": "{{filterName, lowercase}} trouvé avec les filtres suivants:",
    "FILTER_STATUS_TEXT_other": "{{filterName, lowercase}} trouvés avec les filtres suivants:",
    "ACROSS": "à travers",
    "ALL_CATEGORIES": "toutes les catégories",
    "IN_NEED_OF": "ont besoin de",
    "REPLACEMENT": "remplacement",
    "WITH": "avec",
    "WARNINGS": "avertissements",
    "ENROLLMENT": "Inscription",
    "SETTINGS": "Paramètres",
    "ABOUT": "À propos",
    "HELP_CENTER": "Centre d'aide",
    "SIGN_OUT": "Se déconnecter",
    "TO_LOWERCASE": "{{text, lowercase}}",
    "NOUN_AGREEMENT_HOURS": "{{value}} Dernières heures enregistrées",
    "NOUN_AGREEMENT_DAYS": "{{value}} Derniers jours enregistrés",
    "EDIT_FILTERS": "Modifier les filtres",
    "CLEAR_ALL": "Tout effacer",
    "LOADING": "Chargement",
    "MORE": "plus",
    "BATTERY_FIRST_CAPITAL": "Batterie",
    "BATTERY_FIRST_CAPITAL_other": "Batteries",
    "IMEI": "IMEI",
    "CPU": "CPU",
    "IDLE": "Inactif",
    "PROMPT_MESSAGE": "Souhaitez-vous quitter cette page? Il est possible que les modifications que vous avez effectuées ne soient pas enregistrées.",
    FIRST_SEEN: "Première vue",

    // CHARTS
    "NO_DATA_AVAILABLE": "Aucune donnée disponible",
    "RADIO_OFF": 'Radio éteinte',
    "MOBILE_AND_WIFI": "Mobile et Wi-Fi",
    "MOBILE": "Mobile",
    "WIFI": "Wi-Fi",
    "DATA": "Données",

    // PRODUCT SUBTITLES
    "ELEMEZ_BATTERY_SUBTITLE": "Essentials - Suivez, identifiez et remplacez vos batteries avant qu'elles aient un impact sur votre entreprise.",
    "PANASONIC_BATTERY_SUBTITLE": "Monitor - Suivez, identifiez et remplacez vos batteries avant qu'elles aient un impact sur votre entreprise.",
    "ELEMEZ_CORE_V2_SUBTITLE": "Core - Visualisez et prenez des mesures en réponse aux indicateurs de performances clés, aux événements et aux tendances à travers votre flotte mobile.",
    "PANASONIC_CORE_V2_SUBTITLE": "Smart Service - Visualisez et prenez des mesures en réponse aux indicateurs de performances clés, aux événements et aux tendances à travers votre flotte mobile.",

    // CORE SELECTOR BAR
    "OVERVIEW": "Aperçu",
    "DEVICES": "Appareils",
    "APPLICATIONS": "Applications",
    "ANOMALIES": "Anomalies",
    "PERFORMANCE": "Performance",

    // CORE ANOMALY DEVICES TAB

    DEVICE_NOT_FOUND: "Aucun appareil trouvé qui corresponde aux informations fournies",
    MULTIPLE_DEVICES_FOUND: `On a trouvé plus d'un appareil qui correspond aux informations fournies`,

    //CORE DEVICE OVERVIEW TAB
    "ALERTS": " Alertes ({{count}})",
    "CONNECTIONS_AND_ASSETS": "Connexions et composants",
    "LAST_CONTACT": "Dernier contact",
    "LAST_KNOWN_WIFI": "Dernière connexion Wi-Fi connue",
    "NAME_SSID": "Nom (SSID)",
    "ADDRESS_BSSID": "Adresse (BSSID)",
    "UPDATED": "Mis à jour",
    "STATUS": "Statut",
    "STATUS_other": "Statuts",
    "SERIAL": "Numéro de série",
    "SERIAL_list": "Numéro de série de la batterie {{ batteryNumber }}",
    "SIM_SERIAL": "Numéro SIM",
    "SIM_SERIAL_list": "Numéro SIM {{ simNumber }}",
    "OPERATOR": "Opérateur de la carte SIM",
    "OPERATOR_list": "Opérateur de la carte SIM {{ simNumber }}",
    "OPERATOR_CODE": "Code opérateur",
    "SYSTEM": "Informations relatives au système",
    "OPERATING_SYSTEM": "Système d'exploitation",
    "BUILD_VERSION": "Version du système",
    "FIRMWARE_VERSION": "Version du firmware",
    "TIMEZONE": "Fuseau horaire",
    "TIME": "Temps",
    "GROUPS": "Groupes",
    "REPLACE": "Remplacer",
    "WARNING": "Avertissement",
    "GOOD": "Bon",
    "ELEMEZ_VERSION": "Version d'Elemez",
    "EXTENSION_VERSION": `Version de l'extension Elemez`,
    "ENROLLMENT_DATE": "Date de l'inscription",
    "STORAGE": "Stockage",
    "AVAILABLE": "Disponible",
    "TOTAL_SPACE": "Espace total",
    "USED": "Utilisé",
    "MB": "MO",
    "GB": "GO",
    "TOTAL_RAM": "RAM totale",
    "COMPUTER_NAME": "Nom de l'ordinateur",
    "NO_SMART_BATTERY_DETECTED": "Aucune batterie intelligente détectée",
    "NO_SIM_DETECTED": "Aucune carte SIM détectée",
    "SIM": "SIM",

    //OVERVIEW PAGE
    "PROBLEM": "Problème",
    "WELCOME_TITLE": "Bienvenue sur Elemez",
    "ESTATE_ALERT_COUNT": "Problèmes détectés dans votre parc d'appareils. <1>{{totalWithAlerts, localiseNumber}}</1> de vos <3>{{total, localiseNumber}}</3> appareils est<5>en état d'alerte.</5><6>Afficher tous les appareils</6>",
    "ESTATE_ALERT_COUNT_other": "Problèmes détectés dans votre parc d'appareils. <1>{{, localiseNumber}}</1> de vos <3>{{total, localiseNumber}}</3> appareils sont<5>en état d'alerte.</5><6>Afficher tous les appareils</6>",
    "ESTATE_ALERT_COUNT_SIMPLE": "<0>{{totalWithAlerts, localiseNumber}}</0> de vos <2>{{total, localiseNumber}}</2> appareils est<4>en état d'alerte.</4>",
    "ESTATE_ALERT_COUNT_SIMPLE_other": "<0>{{totalWithAlerts, localiseNumber}}</0> de vos <2>{{total, localiseNumber}}</2> appareils sont<4>en état d'alerte.</4>",
    "NO_ISSUES_DETECTED": "Aucun problème détecté dans votre parc d'appareils.",
    "VIEW_DEVICES": "Afficher tous les appareils",

    //DEVICES LIST
    "FILTER_MODAL_HEADER": "Filtres d'appareil",
    "FILTER_MODAL_SUBHEADER": "Filtrer votre liste d'appareils en fonction des catégories et caractéristiques ci-dessous",
    FILTER_MODAL_TAB_1: `Détails de l'appareil`,
    FILTER_MODAL_TAB_2: `Types d'alertes`,
    "FILTER_COUNT_MESSAGE": "Appareils correspondant à vos filtres",
    "FILTER_NONE_SELECTED_MESSAGE": "Aucun filtre selectionné",
    "FILTER_APPLY": "Appliquer les filtres",
    "MANUFACTURER": "Fabricant",
    "MANUFACTURER_other": "Fabricants",
    "MODEL": "Modèle",
    "MODEL_other": "Modèles",
    "SERIAL_NUMBER": "Numéro de série",
    "ASSET_TAG": "Numéro d'inventaire",
    "HOME_LOCATION": "Endroit d'origine",
    "HOME_LOCATION_other": "Endroits d'origine",
    "LAST_SEEN": "Dernier contact",
    "MOBILE_DATA": "Données mobiles",
    "MOBILE_DATA_TOOLTIP_TEXT": "$t(MOBILE_DATA) enregistrées au cours des 30 derniers jours.",
    "WIFI_DATA": "Données Wi-Fi",
    "WIFI_DATA_TOOLTIP_TEXT": "$t(WIFI_DATA) enregistrées au cours des 30 derniers jours.",
    "TOTAL_DATA": "Données Totales",
    "MATCHING_SEARCH": "correspondant à votre recherche",
    "SEARCH_MATCH_STATEMENT": "Résultat correspondant à votre recherche",
    "SEARCH_MATCH_STATEMENT_other": "Résultats correspondant à votre recherche",
    "NAME": "Nom",
    "NAME_REQUIRED": "Nom et Prénom - <1>Obligatoire</1>",
    "JOB_TITLE_REQUIRED": "Titre du Poste - <1>Obligatoire</1>",
    "VERSION": "Version",
    "SIZE": "Taille",
    "SIZE_TOOLTIP_TEXT": "Tailles d'applications de appareils utilisant Android 8 ou élevé.",
    "LAST_UPDATED": "Dernière mise à jour",
    "LAST_USED": "Dernière utilisation",
    "AVERAGE_DAILY_USAGE": "Utilisation quotidienne moyenne",
    "AVERAGE_DAILY_CPU_USAGE": "Utilisation quotidienne moyenne de CPU",
    "USER_EXPERIENCE": "Expérience utilisateur",
    "LOCATION": "Localisation",
    "LOCATION_UPDATED": "Emplacement mis à jour",
    "LAST_LOCATION_LOCK": "Verrouillage du dernier emplacement",
    "LOCATION_UPDATED_TOOLTIP": "Il s'agit de l'heure à laquelle l'agent a enregistré pour la dernière fois le correctif d'emplacement de l'appareil.",
    "LAST_LOCATION_LOCK_TOOLTIP": "Il s'agit de l'heure à laquelle la dernière localisation a été acquise par l'appareil.",
    "UTILISATION": "Utilisation",
    "BATTERY_ESTATE_OVERVIEW_TITLE": "Aperçu des batteries du parc",
    "DEVICE_ESTATE_OVERVIEW_TITLE": "Aperçu des appareils du parc",
    "ESTATE_OVERVIEW_SUBTITLE": "{{count, localiseNumber}} {{label, lowercase}} au total",
    "ESTATE_OVERVIEW_TABLE_TITLE": "Aperçu du parc",
    "ESTATE_OVERVIEW_TABLE_SUBTITLE": `Répartition du statut de l'appareil par groupes et endroits d’origine.`,
    "CORE_ESTATE_OVERVIEW_TABLE_SUBTITLE": "Répartition des alertes immobilières",
    "NO": "non",
    "ALERTS_TEXT": "alertes",
    "NO_SELECTION_TEXT": "Aucun {{item}} sélectionné",
    "DEVICE_STATUS_HEADER": "Statut de l'appareil",
    "DAYS_TEXT": "Jour",
    "DAYS_TEXT_other": "Jours",
    "GROUPS_FILTER_HEADER": "Groupes de filtres",
    "HOMELOCATIONS_FILTER_HEADER": "Filtre d'endroit d'origine",
    "IN_PROGRESS": "En cours",

    "CLOSE": "Fermer",

    "MOBILE_DATA_USED": "Données mobiles utilisées",
    "WIFI_DATA_USED": "Données Wi-Fi utilisées",

    AVERAGE_DISCHARGE: "Décharge moyenne",
    DEVICE_NAME: "Nom de l’appareil",
    OS_VERSION: "Version du OS",
    BIOS_VERSION: "Version du BIOS",
    CLIENT_VERSION: "Version du cliente",
    EXTENSION_VERSION_DEVICE: "Version d'extension",
    "STORAGE_USED": "Stockage utilisé",

    // Pagination bar
    JUMP_TO_PAGE: 'Aller à la page',
    PER_PAGE: 'Par page',
    GO: 'Aller',
    RESULT: "résultat",
    RESULT_other: "résultats",

    CHECKBOX_MESSAGE: "Oui, je comprends.",

    //Processes Tab
    "PROCESSES": "Processus",

    // What's New
    WHATS_NEW: "Quoi de neuf"
  },

  anomaly: {
    // Anomalies page
    ANOMALIES: "$t(translation:ANOMALIES)",
    RESULT: "résultat",
    RESULT_other: "résultats",
    DESCRIPTION: "La description",
    LAST_AFFECTED: "Derniers affectés",
    LAST_AFFECTED_TOOLTIP: "Le nombre d'appareils qui avaient cette caractéristique et ce type d'alerte au moment de la dernière éxecution de l'algorithme.",
    LAST_AFFECTED_TOOLTIP_LABEL: "Plus d'informations sur les derniers affectés",
    TOTAL_AFFECTED: "Total affecté",
    TOTAL_AFFECTED_TOOLTIP: "Le nombre d'appareils uniques qui aient cette caractéristique et ce type d'alerte pendant la période donnée pour cette anomalie.",
    TOTAL_AFFECTED_TOOLTIP_LABEL: "Plus d'informations sur le total affecté",
    HIGHEST_AFFECTED: "Total affecté le plus élevé",
    HIGHEST_AFFECTED_TOOLTIP: "Le nombre le plus élevé d'appareils, qui à la fois partagaient cette caractéristique et ce type d'alerte, qu'on a constaté pendant la période donnée pour cette anomalie.",
    HIGHEST_AFFECTED_TOOLTIP_LABEL: "Plus d'informations sur le total affecté le plus élevé",
    OCCURRENCES: "Evénements",
    OCCURRENCES_TOOLTIP: "Combien de fois le nombre d'appareils avec cette caractéristique et ce type d'alerte qui ont généré une alerte d'anomalie pendant la période donnée.",
    OCCURRENCES_TOOLTIP_LABEL: "Plus d'informations sur événements",
    FIRST_DETECTED: "Première détection",
    FIRST_DETECTED_TOOLTIP: "La première date à laquelle on a généré une alerte d'anomalie pour cette combinaison de caractéristique et type d'alerte.",
    FIRST_DETECTED_TOOLTIP_LABEL: "Plus d'informations sur la première détection",
    LAST_DETECTED: "Dernière détection",
    LAST_DETECTED_TOOLTIP: "La dernière date à laquelle on a généré une alerte d'anomalie pour cette combinaison de caractéristique et type d'alerte.",
    LAST_DETECTED_TOOLTIP_LABEL: "Plus d'informations sur la dernière détection",
    PROPERTY: "Propriété",
    PROPERTY_other: "Propriétés",
    THIS_PROPERTY: "cette propriété",
    THIS_PROPERTY_other: "ces propriétés",
    ALERT_TYPE: `Type d'alerte`,
    LESS_THAN_ONE_PERCENT: "Moins que 1%",
    ACTIVE: "actif",
    ALL: "Tous les",
    ONE_IN_EVERY_STATEMENT: "1 sur {{ratio}}",
    HAS: "a",
    HAS_other: "ont",
    DEVICES_WITH_ALERT_TYPE_STATEMENT: "appareil {{active}} {{has}} ce type d'alerte.",
    DEVICES_WITH_PROPERTY_AND_ALERT_TYPE_STATEMENT: "appareil {{active}} avec cette propriété {{has}} ce type d'alerte.",
    DEVICES_WITH_PROPERTY_AND_ALERT_TYPE_STATEMENT_other: "appareil {{active}} avec ces propriétés {{has}} ce type d'alerte.",
    MORE_THAN_EXPECTED_STATEMENT: "Les appareils dotés de cette propriété ont environ {{multiplier}} fois plus d'alertes de ce type que prévu.",
    MORE_THAN_EXPECTED_STATEMENT_other: "Les appareils dotés de ces propriétés ont environ {{multiplier}} fois plus d'alertes de ce type que prévu.",
    ANOMALY_FILTER_MODAL_HEADER: "Filtre d'anomalie",
    ANOMALY_FILTER_MODAL_SUBHEADER: "Filtrez votre liste d'anomalies par type d'alerte ci-dessous",
    ANOMALY_DETECTION_NOT_SUPPORTED: "Actuellement il y a moins de 50 appareils dans ce monde. Cette fonction nécessite un minimum de 50 appareils dans le monde afin d'opérer.",
    ANOMALY_DETECTION_NOT_SUPPORTED_disabled: "« Détection des Anomalies » est désactivée. Cette fonction nécessite un minimum de 50 appareils dans le monde afin d'opérer et on peut l'activer via « Paramètres ».",

    ANOMALY_DETECTION_SUPPORT_WARNING: "Veuillez noter que cette fonction this feature nécessite un minimum de 50 appareils dans le monde afin d'opérer.",

    // Anomaly details
    ANOMALY_HISTORY: "Historique des anomalies",
    ANOMALY_DEVICE_CHART_TITLE: "Appareils concernés au cours des 90 derniers jours",
    HISTORY: "Histoire",
    DEVICES_AFFECTED_ANOMALY_RAISED: "Appareils concernés (anomalie relevée)",
    DEVICES_AFFECTED_ANOMALY_NOT_RAISED: "Appareils concernés (anomalie non relevée)",

    // Anomaly Devices
    ANOMALY_DEVICES_TABLE_TITLE: "Liste des appareils concernés par cette anomalie sur {{date}}",
    ANOMALY_DEVICES_TITLE: "Liste des appareils concernés",
    ANOMALY_DEVICES_DOWNLOAD_FILE_NAME: "anomalie({{description}})_appareils",
    ANOMALY_DEVICES_LIST_TITLE: "$t(translation:DEVICES_LIST_TITLE)",

    AVERAGE_DISCHARGE_STATUS: `Décharge horaire moyenne élevée`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS: `Point d'accès pas à domicile`,
    DEVICE_LOCATION_DISTANCE_STATUS: `Distance du domicile`,
    DEVICE_LOCATION_TIME_STATUS: `Temps loin du domicile`,
    DEVICE_TIME_ERROR_STATUS: `Erreur de temps`,
    DEVICE_USER_EXPERIENCE_DROP_STATUS: `Chute`,
    DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS: `Faible puissance`,
    DEVICE_USER_EXPERIENCE_REBOOT_STATUS: `Redémarrage`,
    DEVICE_UTILISATION_IDLE_STATUS: `Utilisation inactive`,
    DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS: `Hors contact`,
    DEFAULT_ALERT: `Alerte appareils`,
  },

  redirect: {
    REDIRECT_TO_ANOMALIES: `Revenir à la liste d'anomalies`,
    REDIRECT_TO_APPLICATIONS: `Revenir à la liste d'applications`,
    REDIRECT_TO_DEVICES: `Revenir à la liste d'appareils`,
    REDIRECT_TO_MAPS: 'Voir toutes les cartes',
    REDIRECT_TO_PROCESSES: 'Afficher la liste de tous les processus',
    REDIRECT_TO_ZONES: "Afficher la liste de toutes les zones",

    ANOMALY_NOT_FOUND: 'Aucune anomalie trouvée qui corresponde aux informations fournies',
    APPLICATION_NOT_FOUND: 'Aucune application trouvée qui corresponde aux informations fournies',
    MAP_NOT_FOUND: `Aucune carte n'a été trouvée correspondant aux informations fournies`,
    PROCESS_NOT_FOUND: `Aucun processus correspondant aux informations fournies n'a été trouvé`
  },
  accessDenied: {
    THIS_PAGE: 'cette page',
    THIS_PRODUCT: 'ce produit',
    THIS_WORLD: 'ce monde',
    ACCESS_DENIED_PREFIX: `Vous n'avez pas la permission d'accéder à`,
    ACCESS_DENIED_TO_PAGE: '$t(ACCESS_DENIED_PREFIX) $t(THIS_PAGE).',
    ACCESS_DENIED_TO_PRODUCT: '$t(ACCESS_DENIED_PREFIX) $t(THIS_PRODUCT).',
    ACCESS_DENIED_TO_WORLD: '$t(ACCESS_DENIED_PREFIX) $t(THIS_WORLD).',
    CONTACT_ADMIN_MESSAGE: 'Pour de plus amples renseignements, veuillez contacter votre administrateur système.',
    RETURN_TO_PRODUCT_SELECTION: 'Revenir à la sélection de produits',
    RETURN_TO_WORLD_SELECTION: 'Revenir à la sélection de mondes'
  },
  worldSelection: {
    TITLE: 'Sélection de mondes',
    SELECT_A_WORLD: `Veuiller sélectionner le monde que vous souhaitez voir`
  },
  editRecord: {
    TITLE: "Modifier l'enregistrement",
    SUBHEADER: "Modifier le numéro d'inventaire, l'endroit d'origine et les groupes de cet appareil.",
    ERROR_MESSAGE: `La mise à jour de la valeur suivante a échoué: {{fields, list}}. Veuillez réessayer.`,
    ERROR_MESSAGE_other: `La mise à jour des valeurs suivantes a échoué: {{fields, list}}. Veuillez réessayer.`,
    ASSET_TAG_VALIDATION_ERR: "Les numéros d'inventaire doivent comporter au maximum {{maxLength}} caractères.",
    GROUP_VALIDATION_ERR_COMMAS: 'Les groupes ne doivent pas inclure de virgules',
    GROUP_VALIDATION_ERR_LENGTH: 'Les groupes doivent comporter au maximum 20 {{maxLength}} caractères',
    NO_HOME_LOCATION: '$t(translation:NO_HOME_LOCATION)',
    GROUP_REMOVE: 'Cliquez pour supprimer le groupe',
    GROUP_PLACEHOLDER: 'Ajouter un groupe'
  },

  editEvents: {
    // EDIT EVENTS MODAL
    OPEN_BUTTON: "Afficher les événements",
    FILTER_BUTTON: "Filtrer les événements",
    DISPLAY_ALL: "Montrer tous les événements",
    ICON: "Symbole",
    APPLICATIONS_GROUP: "Les applications",
    BATTERY_GROUP: "Batterie",
    POWER_GROUP: "Puissance",
    USER_EX_GROUP: "L'Éxpérience Utilisateur",
    NETWORK: "Réseau",
    APPLY_BUTTON: "Appliquer",
    OR: "ou",
    TIME_GROUP: "Synchronisation horaire",
    MODAL_HEADER: "Marquers d'événements affichés",
    MODAL_TABLE_HEADER: "Filtrer la liste d'événements",
    MODAL_SUBHEADER: "Modifier les types d'événements affichés sur les tableaux de performance.",
    MODAL_TABLE_SUBHEADER: "Filtrer les événements affichés sur la liste d'événements de la page performance.",
    EDITING: "Modifications",
    APPLY_ALL_BUTTON: "Appliquer à tous les tableaux",
    RADIO_GROUP: "Radio",

    // EDIT EVENTS MODAL DEVICE EVENTS
    APPLICATION_INSTALLED: "L'Installation d'une application",
    APPLICATION_UNINSTALLED: "Désinstallation d'une application",
    APPLICATION_UPDATED: "Mise à jour d'une application",
    BATTERY_SWAP: "Remplacement de batterie",
    DEVICE_ON_POWER: "En charge",
    DEVICE_OFF_POWER: "Décharge",
    DEVICE_LOW_POWER: "Faible puissance",
    DEVICE_REBOOTED: "Redémarrage",
    DEVICE_DROP: "Chute",
    TIME_ERROR: "Erreur de temps",
    TIME_RECOVERY: "Récupération du temps",
    NETWORK_AVAILABLE: "Réseau disponible",
    NETWORK_LOST: "Réseau perdu",
    NETWORK_CHANGED: "Changement de réseau",
    BEARER_CHANGED: "Changement de porteur",
    SIM_CHANGED: "Changement de carte SIM",
    AIRPLANE_MODE_OFF: "Mode avion désactivé",
    AIRPLANE_MODE_ON: "Mode avion activé",
    MOBILE_RADIO_OFF: "Radio mobile éteinte",
    MOBILE_RADIO_ON: "Radio mobile allumée",
  },
  "formControls": {
    "DELETE": "Supprimer",
    "DELETE_other": "Supprimer ({{count}})",
    "SAVE": "Enregistrer",
    "SAVE_other": "Enregistrer ({{count}})",
    "REVIEW": "Réviser",
    "REVIEW_other": "Réviser ({{count}})",
    "APPLY": "Appliquer",
    "APPLY_other": "Appliquer ({{count}})",
  },
  devicesList: {
    "DEVICE_ACROSS": "appareil à travers",
    "DEVICE_ACROSS_other": "appareils à travers",
    "ELEMEZ_COREv2_CATEGORIES": "toutes les catégories"
  },
  deleteDialogue: {
    CONFIRMATION_QUESTION: "Êtes-vous sûr?",
    CANCEL_DELETION: "Non, annuler la suppression",
    ERROR: "$t(error:SOMETHING_WRONG_RETRY)",
    ERROR_CUSTOM_DELETE: "$t(error:SOMETHING_WRONG) Il peut y avoir eu un ou plusieurs {{ item, lowercase }} qui n'ont pas été supprimés. Veuillez réessayer."
  },
  deleteSmartBatteries: {
    SUBHEADER: "{{count}} batterie sera supprimé.",
    SUBHEADER_other: "{{count}} batteries seront supprimés.",
    MESSAGE: "Vous ne pourrez plus afficher cette batterie et ses analyses individuelles.",
    MESSAGE_other: "Vous ne pourrez plus afficher ces batteries et leurs analyses individuelles.",
    CONFIRM_BATTERIES_DELETE: "Supprimer la batterie",
    CONFIRM_BATTERIES_DELETE_other: "Supprimer les batteries"
  },
  deleteDevices: {
    SUBHEADER: "{{count}} appareil sera supprimé.",
    SUBHEADER_other: "{{count}} appareils seront supprimés.",
    MESSAGE: "Vous ne pourrez plus afficher cet appareil et ses analyses individuelles.",
    MESSAGE_other: "Vous ne pourrez plus afficher ces appareils et leurs analyses individuelles.",
    CONFIRM_DELETE: "Oui, supprimez cet appareil",
    CONFIRM_DELETE_other: "Oui, supprimer ces appareils"
  },
  deleteUsers: {
    SUBHEADER: "{{count}} utilisateur sera supprimé.",
    SUBHEADER_other: "{{count}} utilisateurs seront supprimés.",
    MESSAGE: "Vous ne pourrez plus afficher cet utilisateur et il ne pourra plus se connecter.",
    MESSAGE_other: "Vous ne pourrez plus afficher ces utilisateurs et ils ne pourront plus se connecter.",
    CONFIRM_DELETE: "Oui, supprimez cet utilisateur",
    CONFIRM_DELETE_other: "Oui, supprimer ces utilisateurs"
  },
  deleteHomeLocations: {
    SUBHEADER_SINGLE: "« {{ location }} » sera supprimé.",
    SUBHEADER: "{{ count }} endroit d'origine sera supprimé.",
    SUBHEADER_other: "{{ count }} endroits d'origine seront supprimés.",
    MESSAGE: "Vous ne pourrez plus attribuer des appareils ou des utilisateurs à cet endroit d'origine.",
    MESSAGE_other: "Vous ne pourrez plus attribuer des appareils ou des utilisateurs à ces endroits d'origine.",
    CONFIRM_DELETE: "Oui, supprimer cet endroit d'origine",
    CONFIRM_DELETE_other: "Oui, supprimer ces endroits d'origine",
    FAILURE_HEADER: "On ne peut pas supprimer l'endroit d'origine",
    FAILURE_HEADER_other: "On ne peut pas supprimer les endroits d'origine",
    FAILURE_HEADER_DEVICES: "On ne peut pas supprimer l'endroit d'origine suivant, parce que des appareils lui sont attribués:",
    FAILURE_HEADER_DEVICES_other: "On ne peut pas supprimer les endroits d'origine suivants, parce que des appareils leur sont attribués:",
    FAILURE_FOOTER_DEVICES: "Vous pouvez modifier l'endroit d'origine de ces appareils individuellement ou en masse.",
    FAILURE_HEADER_USERS: "On ne peut pas supprimer l'endroit d'origine suivant, parce que des utilisateurs lui sont attribués:",
    FAILURE_HEADER_USERS_other: "On ne peut pas supprimer les endroits d'origine suivants, parce que des utilisateurs leur sont attribués:",
    FAILURE_FOOTER_USERS: "Vous pouvez modifier les endroits d'origine de ces utilisateurs individuellement.",
    FAILURE_HEADER_ZONE: "L'emplacement d'origine suivant ne peut pas être supprimé car il est attribué à une zone:",
    FAILURE_HEADER_ZONE_other: "Les emplacements d'origine suivants ne peuvent pas être supprimés car ils sont attribués à une zone:",
    FAILURE_FOOTER_ZONE: "Vous pouvez supprimer des emplacements résidentiels de ces zones individuellement.",
    VIEW_BULK_ACTIONS: "Voir Actions en masse"
  },
  timeState: {
    TODAY: `Aujourd'hui`,
    YESTERDAY: "Hier",
    HOUR: "heure",
    HOUR_other: "heures",
    MIN: "min",
    MINUTE: "minute",
    MINUTE_other: "minutes",
    HOURS_24: "24 Heures",
    HOURS_48: "48 Heures",
    DAYS_7: "7 Jours",
    LESS_THAN_A_MONTH: "Moins d'un mois",
    MONTH: "mois",
    MONTH_other: "mois",
    YEAR: "an",
    YEAR_other: "ans",
    HOUR_COUNT: '{{ count }} heure',
    HOUR_COUNT_other: '{{ count }} heures',
    DAY_COUNT: '{{ count }} jour',
    DAY_COUNT_other: '{{ count }} jours',
    OVER_THE_LAST_DAYS: 'au cours des {{ count }} derniers jours',
    IN_THE_LAST_F: 'dans la dernière',
    IN_THE_LAST_F_other: 'dans les dernières',
    IN_THE_LAST_M: 'dans le dernier',
    IN_THE_LAST_M_other: 'dans les derniers',
    LAST_N_DAYS: 'Derniers $t(timeState:DAY_COUNT_other, { "count": {{count}} })',
    LAST_N_HOURS: 'Derniers $t(timeState:HOUR_COUNT_other, { "count": {{count}} })',
    MORE_THAN_90_DAYS_AGO: 'Il y a plus de 90 jours',
    OVER_THE_PAST_X_DAYS: 'au cours des {{ count }} derniers jours',
    OVER_THE_PAST_X_HOURS: 'au cours des {{ count }} dernières heures'
  },
  distance: {
    METRE: 'mètre',
    METRE_other: 'mètres',
    KILOMETRE: 'kilomètre',
    KILOMETRE_other: 'kilomètres',
    YARD: 'yard',
    YARD_other: 'yards',
    MILE: 'mile',
    MILE_other: 'miles',
    SHORT_metric: '$t(distance:METRE)',
    SHORT_imperial: '$t(distance:YARD)',
    SHORT_metric_other: '$t(distance:METRE_other)',
    SHORT_imperial_other: '$t(distance:YARD_other)',
    LONG_metric: '$t(distance:KILOMETRE)',
    LONG_imperial: '$t(distance:MILE)',
    LONG_ABBREVIATION_imperial: 'mi',
    LONG_ABBREVIATION_metric: 'km',
    LONG_metric_other: '$t(distance:KILOMETRE_other)',
    LONG_imperial_other: '$t(distance:MILE_other)'
  },
  performance: {
    "DEVICE_PERFORMANCE_DESCRIPTION": "Des rapports configurables sur les événements et l'utilisation récente de cet appareil.",
    "VIEW_LATEST_RECORDS": "Afficher les derniers enregistrements",
    "SELECTED_DATE": "Date sélectionnée",
    "LAST_24HOURS_TEXT": "Les 24 dernières heures",
    "LAST_30DAYS_TEXT": "Les 30 derniers jours",
    "LAST_KNOWN_BATTERY": "Dernière batterie connue",
    "LAST_KNOWN_BATTERIES": "Dernières batteries connues",
    "PRINT_REPORT": "Imprimer le rapport",
    "NOW": "Maintenant",
    "AT": "à",
    "WIFI_DATA_USED": "Données Wi-Fi utilisées",
    "MOBILE_DATA_USED": "$t(translation:MOBILE_DATA_USED)",
    "DROPS": "Chutes",
    "REBOOTS": "Redémarrages",
    "LOW_POWER": "Événements de faible Puissance",
    "BATTERY_SWAPS": "Remplacements de batterie",
    "TIME_ERRORS": "Erreurs de temps",
    "IN_USE": "En usage",
    "DEVICE_MINUTES_USAGE_PER_HOUR": "Utilisation en minutes de l'appareil par heure",
    "LESS_THAN_ONE_MINUTE_USAGE": "Moins d'une minute d'utilisation",
    "DEVICES_USED": "Utilisé l'appareils",
    "MOBILE_DATA": "Données mobiles",
    "WIFI_DATA": "Données Wi-Fi",
    "MOBILE_DATA_LINES": "Données\n mobiles",
    "WIFI_DATA_LINES": "Données\n Wi-Fi",
    "TOTAL_DATA": "Données Totales",
    "DATA_USAGE_HOUR_WIFI_MOBILE": "Utilisation des données par heure (Wi-Fi + Mobile)",
    "DEVICE_CHARGE_LEVEL": "Niveau de charge de l'appareil",
    "CHARGE_LEVEL": "Niveau de charge",
    "BATTERY_SN": "NS de la batterie",
    REBOOT_TYPE_USER: `Lancé par l'utilisateur`,
    REBOOT_TYPE_SYSTEM: 'Lancé par le système',
    "DEVICE_DATA_USAGE_PAST_30DAYS": "Utilisation des données de l'appareil au cours des 30 derniers jours",
    "DEVICE_UTILISATION_PAST_30_DAYS": "Minutes d'utilisation au cours des 30 derniers jours",
    "AVG_HOURLY_DISCHARGE": "Décharge Horaire Moyenne",
    "AVG_HOURLY_DISCHARGE_LINES": "Décharge\n Horaire\n Moyenne",
    "AVERAGE_HOURLY_DISCHARGE_PAST_30DAYS": "Décharge horaire moyenne au cours des 30 derniers jours",
    "EVENTS_HEADER": "Événement ({{count}})",
    "EVENTS_HEADER_other": "Événements ({{count}})",
    "EVENTS_FOOTER": "événement détectés pendant cette période",
    "EVENTS_FOOTER_other": "événements détectés pendant cette période",
    "SHOW_EVENTS_TEXT": "Afficher la liste des événements",
    "HIDE_EVENTS_TEXT": "Masquer la liste des événements",
    "EVENT_TYPE": "Type d'événement",
    "EVENT_TIME": "Heure de l'événement",
    "EVENT_INFORMATION": "Informations sur l'événement",
    "UPDATED_VERSION_TEXT": "mis à jour à la version",
    "EVENTS_SHOWN": "Événements affichés",
    "ALL_EVENTS": "Tous les événements",
    "NONE": "aucun",
    "UNINSTALLED_TEXT": "désinstallé",
    "INSTALLED_TEXT": "installé",
    "TOOLTIP_TEXT": "Voir la liste des événements pour tous",
    "OUT": "de",
    "IN": "à",
    "OLD": "Vieille",
    "NEW": "Nouvelle",
    "RESET_ZOOM": "Remettre le zoom",
    PERFORMANCE_CHART_TITLE_DEVICES_USAGE: `Utilisation de '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_DEVICES_USAGE_withFilter: `Utilisation de '{{ filter }}' $t(translation:DEVICE_other, lowercase) {{ period }}`,
    PERFORMANCE_CHART_TITLE_DATA_USAGE: `Utilisation des données de '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_REBOOTS: `Redémarrage de '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_LOW_POWER: `Événements de faible puissance de '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_TIME_ERRORS: `Erreurs de temps de '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_BATTERY_SWAPS: `Échanges de batteries de '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_DROPS: `Des chutes de '{{ filter }}' {{ period }}`
  },
  deviceLocation: {
    "DEVICE_LOCATION": "Emplacement de l'appareil",
    "DEVICE_LOCATION_DESCRIPTION": "Localisez votre appareil et son endroit d'origine. Mis à jour:",
    "MAP_KEY": "Légende:",
    "THIS_DEVICE": "Cet appareil",
    "DEVICE_ACCURACY_RADIUS": "Rayon de précision de l'appareil",
    "HOME_LOCATION_RADIUS": "Rayon de l'endroit d'origine",
    "LOCATION_UNKNOWN": "Localisation Inconnue",
    "ACCURACY": "Précision",
    "SOURCE": "Source"
  },
  deviceProcesses: {
    "DEVICE_PROCESS": "Processus",
    "DEVICE_PROCESS_other": "Processus",
    //Title
    "PROCESSES_TITLE": "Processus de l'appareil",
    "PROCESSES_SUBTITLE": "Sélectionnez un processus de la liste ci-dessous pour voir son historique, son usage et ses événements.",
    "PROCESSES_ON_DEVICE": "Processus sur cet appareil",
    //table headings
    "PROCESS_NAME": "Nom du processus",
    "PRODUCT_NAME": "Nom du produit",
    "PRODUCT_VERSION": "Version du produit",
    "FILE_VERSION": "Version du fichier",
    //footer statement
    "DEVICE_PROCESS_TOTAL": "processus sur cet appareil",
    "DEVICE_PROCESS_TOTAL_other": "processus sur cet appareil",
    //overview labels
    "DETECTED_ON_DEVICE": "détecté sur cet appareil",
    "DETECTED_ON_DEVICE_other": "détectés sur cet appareil",
    "USED_PROCESSES": "utilisé au cours des {{ days }} derniers jours",
    "USED_PROCESSES_other": "utilisés au cours des {{ days }} derniers jours",
    //search
    "USED_TODAY": "utilisé aujourd'hui",
    "USED_TODAY_other": "utilisés aujourd'hui",
    "USAGE": "Usage",
    "CPU_USAGE": "Usage de CPU",
    "CPU": "$t(translation:CPU)",
    "USAGE_TITLE_daily": "Utilisation quotidienne {{ timePeriod }}",
    "USAGE_TITLE_hourly": "Utilisation horaire {{ timePeriod }}",
    "CPU_USAGE_TITLE_daily": "Utilisation quotidienne de CPU {{ timePeriod }}",
    "CPU_USAGE_TITLE_hourly": "Utilisation horaire de CPU {{ timePeriod }}"
  },
  applications: {
    "SHARED_ID": "Identifiant utilisateur partagé",
    "SHARED_ID_TOOLTIP_TEXT": "Un identifiant partagé permet que des groupes d'applications connexes fonctionnent ensemble. Les applications avec un identifiant d'utilisateur partagé partagent également les mêmes valeurs pour l'utilisation et la taille des données.",
    "APPLICATION": "application",
    "APPLICATION_other": "applications"
  },
  deviceApplications: {
    "OVERVIEW_TITLE": "Aperçu des applications",
    "OVERVIEW_SUBTITLE": "Sélectionnez une application dans la liste ci-dessous pour afficher son historique, son utilisation et ses événements.",
    "APPLICATION_ON_DEVICE": "Application sur cet appareil",
    "APPLICATION_ON_DEVICE_other": "Applications sur cet appareil",
    get APPLICATION_ON_DEVICE_OVERVIEW() { return this.APPLICATION_ON_DEVICE.toLowerCase(); },
    get APPLICATION_ON_DEVICE_OVERVIEW_other() { return this.APPLICATION_ON_DEVICE_other.toLowerCase(); },
    "DEVICE_APPLICATION_TOTAL": "application sur cet appareil",
    "DEVICE_APPLICATION_TOTAL_other": "applications sur cet appareil",
    "INSTALLED_APPLICATIONS": "installée au cours des 30 derniers jours",
    "INSTALLED_APPLICATIONS_other": "installées au cours des 30 derniers jours",
    "UPDATED_APPLICATIONS": "mise à jour au cours des 30 derniers jours",
    "UPDATED_APPLICATIONS_other": "mises à jour au cours des 30 derniers jours",
    "USED_APPLICATIONS": "utilisée au cours des 14 derniers jours",
    "USED_APPLICATIONS_other": "utilisées au cours des 14 derniers jours",
    "USED_TODAY": "utilisée aujourd'hui",
    "USED_TODAY_other": "utilisées aujourd'hui",
    "SHARED_ID": "$t(applications:SHARED_ID)",
    "SHARED_ID_TOOLTIP_TEXT": "$t(applications:SHARED_ID_TOOLTIP_TEXT)",
    "DAILY_USAGE_PAST_30DAYS": "Utilisation quotidienne au cours des 30 derniers jours",
    "DATA_USAGE_TITLE_daily": "Utilisation quotidienne des données {{ timePeriod }}",
    "DATA_USAGE_TITLE_hourly": "Utilisation horaire des données {{ timePeriod }}",
    "PACKAGE_NAME": "Nom du paquet"
  },
  coreApplications: {
    "NAME": "Nom",
    "SHARED_ID": "$t(applications:SHARED_ID)",
    "SHARED_ID_TOOLTIP_TEXT": "$t(applications:SHARED_ID_TOOLTIP_TEXT)",
    "VERSION_COUNT": "Versions",
    "INSTALLED_COUNT": "Instances",
    "CURRENT_VERSION_COUNT": "Versions actuelles",
    "CURRENT_INSTALLED_COUNT": "Instances actuelles",
    "LATEST_VERSION": "Dernière version",
    "LAST_USED": "$t(translation:LAST_USED)",
    "USED_COUNT": "Nombre d'appareils qui l'utilisent",
    "USED_COUNT_TOOLTIP_TEXT": "Nombre d'appareils qui ont utilisé cette application au cours des 30 derniers jours.",
    "FIRST_INSTALLED": "Première installation",
    "MOBILE_DATA_USED": "$t(translation:MOBILE_DATA_USED)",
    "TOTAL_MINUTES_USAGE": "Le total de minutes de l'utilisation",
    "WIFI_DATA_USED": "Données Wi-Fi utilisées",
    "AVERAGE_MINUTES_USED": "Utilisation quotidienne moyenne",
    "VERSION_FILTER": " avec la version {{ version }} de cette application",
    "GROUP_FILTER": "tous les appareils de la groupe {{ name }}",
    "ZONE_FILTER": "tous les appareils de la zone {{ name }}",
    "HOME_LOCATION_FILTER": "tous les appareils de l'endroit d'origine {{ name }}",
    "NO_GROUP_FILTER": "tous les appareils sans groupe",
    "NO_HOME_LOCATION_FILTER": "tous les appareils sans endroit d'origine",
    "SECTION_TITLE_OVERVIEW": `Aperçu de l'application de {{ filter }}`,
    "SECTION_TITLE_OVERVIEW_withVersion": `Aperçu de l'application de {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_DATA_USAGE": `Utilisation des données de l'application de {{ filter }}`,
    "SECTION_TITLE_DATA_USAGE_withVersion": `Utilisation des données de l'application de {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_DEVICE_USAGE": `Utilisation de l'application de {{ filter }}`,
    "SECTION_TITLE_DEVICE_USAGE_withVersion": `Utilisation de l'application de {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_MINUTES_USAGE": `Minutes d'utilisation de l'application de {{ filter }}`,
    "SECTION_TITLE_MINUTES_USAGE_withVersion": `Minutes d'utilisation de l'application de {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_APPLICATION_DEVICES": `{{ filter }} avec cette application`,
    "SECTION_TITLE_APPLICATION_DEVICES_withVersion": `{{ filter }}{{versionFilter}}`,
    "ALL_DEVICES": `Tous les appareils`,
    get ALL_DEVICES_lowercase() { return `${this.ALL_DEVICES.toLowerCase()}`; },
    "PERFORMANCE": "Performance",
    "DEVICES_USED": "Nombre d'appareils qui ont utilisé l'application",
    "MINUTES_USED": "Minutes d'utilisation",
    "ALL_APPLICATIONS": "Toutes les applications",
    "USER_APPLICATIONS": "Applications de l'utilisateur",
    "SYSTEM_APPLICATIONS": "Applications du système",
    "ALL_APPLICATION_VERSIONS": "Toutes",
    "APP_VERSION": "Version de l'application",
    "APP_VERSION_filter": "Version de l'application:",
    "APPLICATION_DEVICES_LIST_TITLE": "$t(translation:DEVICES_LIST_TITLE)",
    "APP_LAST_USED": "Dernière utilisation de l'application",
    "MOBILE_DATA_FULL": "Application données mobiles",
    "WIFI_DATA_FULL": "Application données Wi-Fi",
    "WIFI_DATA_TOOLTIP_TEXT": "Les données Wi-Fi utilisées par cette application sur l'appareil au cours des 30 derniers jours.",
    "MOBILE_DATA_TOOLTIP_TEXT": "Les données mobiles utilisées par cette application sur l'appareil au cours des 30 derniers jours.",
    "VERSIONS_TOOLTIP": "Liste de versions de l'application qui ont communiqué avec Elemez pendant la période de temps sélectionnée",
    "SHARED_USER_ID_INFO": "Les valeurs des données ici et celles préséntées dans le graphique de l'utilisation des données dans cette page sont valeurs cumulées de toutes les applications et leurs versions qui utilisent l'identifiant utilisateur partagé \"{{ sharedUserId }}\"",
    "SECTION_TITLE_SHARED_USER_ID": `Utilisation des données de {{ filter }} qui utilisent l'identifiant utilisateur partagé "{{ sharedUserId }}"`
  },
  coreProcesses: {
    "PROCESS": "Processus",
    "DEVICE_LIST": "Liste des périphériques",
    "PROCESS_other": "Processus",
    "PROCESSES_TITLE": "Processus Windows",
    "PROCESS_NAME": "Nom du processus",
    "PRODUCT_NAME": "Nom du produit",
    "SUBHEADER": "Processus observés en cours d’exécution dans le domaine au cours des 30 derniers jours",
    "ACTIVE_DEVICES": "Appareils actifs",
    "VERSIONS": "Versions",
    "LATEST_PRODUCT_VERSION": "Dernière version du produit",
    "LATEST_FILE_VERSION": "Dernière version du fichier",
    "USED_DEVICES": "Appareils utilisés",
    "LAST_USED": "$t(translation:LAST_USED)",
    "ACTIVE_DEVICES_TOOLTIP_TEXT": "Nombre d'appareils sur lesquels cette instance de processus a été exécutée au cours des 30 derniers jours.",
    "USED_COUNT_TOOLTIP_TEXT": "Nombre d'appareils sur lesquels cette instance de processus a eu une interaction utilisateur au cours des 30 derniers jours.",
    "PERFORMANCE": "Performance",
    "ALL_PROCESS_VERSIONS": "$t(translation:ALL)",
    "DEVICES_USED": 'Appareils utilisés',
    "ALL_DEVICES": `Tous les dispositifs`,
    get ALL_DEVICES_lowercase() { return `${this.ALL_DEVICES.toLowerCase()}`; },
    "GROUP_FILTER": "appareils dans le groupe {{ name }}",
    "ZONE_FILTER": "appareils dans la zone {{ name }}",
    "HOME_LOCATION_FILTER": "appareils dans l'emplacement du domicile {{ name }}",
    "NO_GROUP_FILTER": "appareils sans groupes",
    "NO_HOME_LOCATION_FILTER": "appareils sans emplacement personnel",
    "VERSION_FILTER": "avec la version {{ version }} de ce processus",
    "SECTION_TITLE_CPU_USAGE": `Traiter l'utilisation du processeur dans {{ filter }}`,
    "SECTION_TITLE_CPU_USAGE_withVersion": `Traiter l'utilisation du processeur dans {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_USAGE": `Utilisation des processus à travers {{ filter }}`,
    "SECTION_TITLE_USAGE_withVersion": `Utilisation des processus à travers {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_UTILIZATION": `Utilisation des processus à travers {{ filter }}`,
    "SECTION_TITLE_UTILIZATION_withVersion": `Utilisation des processus à travers {{ filter }}{{versionFilter}}`,
    "DEVICE_LIST_TITLE": `{{filter}} avec ce processus`,
    "DEVICE_LIST_TITLE_withVersion": `{{filter}}{{versionFilter}}`,
    "MINUTES_USED": "Minutes d'utilisation",
    "CPU_MINUTES_USED": "Minutes CPU Utilisées",
    "VERSION_TOOLTIP": "Liste des combinaisons version du produit/version du fichier vues au cours de la période sélectionnée"
  },
  alerts: {
    "NO_ALERTS": "Aucun problème actuellement détecté sur cet appareil",
    "DEVICE_LOCATION_DISTANCE_STATUS_metric": "Cet appareil se trouve à plus de <1>{{threshold}} $t(distance:KILOMETRE_other)</1> de son endroit d'origine",
    "DEVICE_LOCATION_DISTANCE_STATUS_imperial": "Cet appareil se trouve à plus de <1>{{threshold}} $t(distance:MILE_other)</1> de son endroit d'origine",
    "DEVICE_LOCATION_TIME_STATUS": "Cet appareil se trouve éloigné de son endroit d'origine depuis plus de <1>{{threshold}} heures</1>",
    "NO_LOCATION_ALERT": "Aucun problème de localisation détecté sur cet appareil",
    "NO_UTILISATION_ALERT": "Aucun problème d'utilisation détecté sur cet appareil",
    "NO_USER_EXPERIENCE_ALERT": "Aucun problème d'expérience utilisateur détecté sur cet appareil",
    "DEVICE_UTILISATION_OUT_OF_CONTACT_HOUR_ZERO_STATUS": "Cet appareil est <1>hors de contact</1> depuis moins d'une heure",
    "DEVICE_UTILISATION_OUT_OF_CONTACT_HOUR_STATUS": "Cet appareil est <1>hors de contact</1> depuis {{hoursSpentOutOfContact}} heure",
    "DEVICE_UTILISATION_OUT_OF_CONTACT_HOUR_STATUS_other": "Cet appareil est <1>hors de contact</1> depuis {{hoursSpentOutOfContact}} heures",
    "DEVICE_UTILISATION_OUT_OF_CONTACT_DAY_STATUS": "Cet appareil est <1>hors de contact</1> depuis le {{lastUpdated}} (<5>{{daysSpentOutOfContact}} jour</5>)",
    "DEVICE_UTILISATION_OUT_OF_CONTACT_DAY_STATUS_other": "Cet appareil est <1>hors de contact</1> depuis {{lastUpdated}} (<5>{{daysSpentOutOfContact}} jours</5>)",
    "DEVICE_IDLE_DAY_STATUS": "Cet appareil est <1>inactif</1> depuis <3>{{configDays}}</3> ou plus <5>jours</5>",
    "HOURS": "dernières heures",
    "DAYS": "{{thresholdPeriod}} derniers jours",
    "DEVICE_REBOOT_STATUS": "Cet appareil a été <1>redémarré {{deviceEvent}} heure</1> au cours des ",
    "DEVICE_REBOOT_STATUS_other": "Cet appareil a été <1>redémarré {{deviceEvent}} fois</1> au cours des ",
    "DEVICE_DROP_STATUS": "Cet appareil a été <1>supprimé {{deviceEvent}} heure</1> au cours des ",
    "DEVICE_DROP_STATUS_other": "Appareil <1>laissé tomber</1>  <1>{{deviceEvent}} fois</1> au cours des ",
    "DEVICE_LOW_POWER_STATUS": "Il y a eu <1>{{deviceEvent}} événement de faible puissance</1> sur cet appareil au cours des ",
    "DEVICE_LOW_POWER_STATUS_other": "Il y a eu <1>{{deviceEvent}} événements de faible puissance</1> sur ces appareils au cours des ",
    "TIME_ERROR_STATUS": "Cet appareil est en erreur de temps",
    "AVG_DISCHARGE_STATUS": "Cet appareil enregistrait une <1>décharge moyenne élevée de {{discharge}}%</1> au cours des 30 derniers jours",
    "THIS_DEVICE_IS": "Cet appareil est connecté à",
    "ON_A_KNOWN_NETWORK": "un réseau connu",
    "ON_AN_UNKNOWN_NETWORK": "un réseau inconnu",
    "AT_ACCESS_POINT_LOCATION_NAME": "à",
    "NOT_BELONGING_TO_HOME_LOCATION": "qui n'appartient pas à son endroit d'origine"
  },
  agreements: {
    EULA_FULL: `Contrat de licence de l'utilisateur final`,
    THE_EULA_FULL: 'le contrat de licence utilisateur final',
    EULA_ABBREV: 'CLUF',
    THE_EULA_ABBREV: 'le CLUF',
    DPA_FULL: 'Accord de traitement des données',
    THE_DPA_FULL: `l'accord de traitement des données`,
    DPA_ABBREV: 'DPA',
    THE_DPA_ABBREV: 'le DPA',
    BOTH_EULA_DPA: 'le CLUF et le DPA',
    OPEN_IN_NEW_TAB: 'Ouvrez {{v}} dans un nouvel onglet',
    AGREE_TERMS: `J'accepte les termes et conditions du {{v}}`,
    AGREEMENT_DESCRIPTION: `By accepting this agreement you warrant that you have the authority to accept and bind your organisation to its content. Clicking the Accept button is considered equivalent to your signature on this agreement.`,
    AGREEMENT_DESCRIPTION_other: `By accepting these agreements you warrant that you have the authority to accept and bind your organisation to their content. Clicking the Accept button is considered equivalent to your signature on these agreements.`,
    AGREEMENTS_READ: `Veuillez vous <1>assurer d'avoir lu attentivement</1> {{v}} avant d'accepter:`,
    RETURN_LOG_IN: 'Retour à la connexion',
    ACCEPT: 'Accepter',
    ERROR: `$t(error:SOMETHING_WRONG_RETRY)`,
    EULA_AND_DPA_DESCRIPTION: `Votre {{v}}`
  },
  enrollment: {
    SECTION_TITLE: 'Inscription',
    SECTION_SUBTITLE: 'Inscription',
    TOKEN_HEADER: 'Jeton Elemez',
    TOKEN_SUBHEADER: `Le jeton est requis si vous distribuez et installez l'agent Elemez à l'aide d'un MDM ou d'un EMM.`,
    NO_TOKEN_TEXT: 'Aucun jeton disponible',
    TOKEN_COPY_SUCCESS: "Jeton copié",
    TOKEN_COPY_FAIL: "Échec de la copie"
  },
  batteryEssentials: {
    NO_SELECTION_HEADER: "Bienvenue sur {{title}}",
    BATTERY_NOT_FOUND: "Aucune batterie trouvée",
    BATTERY_NOT_FOUND_BODY: "La batterie que vous recherchez n'a pas été trouvée. Veuillez sélectionner une batterie de la liste ci-dessous.",
    NO_SELECTION_BODY_UPPER: "Sélectionnez une batterie de la liste ci-dessous pour en savoir plus sur sa santé et son historique de niveau de charge.",
    NO_SELECTION_BODY_LOWER: "Chaque rapport de batterie s'accompagne de renseignements sur le dernier appareil dans lequel on l'a trouvée.",
    NO_BATTERY_SELECTED: "Aucune batterie sélectionnée",
    BATTERY_STATUS_HEADER: "Statut de la batterie",
    BATTERY_IDENTIFIERS: "Identificateurs de la batterie",
    PART_NUMBER: `Numéro de pièce`,
    MANUFACTURE_DATE: "Date de fabrication",
    HEALTH: "Santé",
    CAPACITY: "Capacité (mAh)",
    CYCLE_COUNT: "Nombre de cycles",
    AGE: "Âge",
    LAST_KNOWN_DEVICE: "Dernier appareil connu",
    GROUPS_FILTER_HEADER: "Groupes filtre",
    UNDER_WARRANTY_FILTER_HEADER: "Filtre de garantie",
    UNDER_WARRANTY: "Sous garantie",
    CHARGE_LEVEL_STATEMENT: "Niveau de charge sur le {{timePeriod, lowercase}}",
    TIME_PERIOD: "Période de temps:",
    BATTERY_SERIAL_NUMBER: "Numéro de série de la batterie",
    BATTERY_PART_NUMBER: "Numéro de pièce de la batterie",
    VIEW_IN_DEVICES: "Voir dans appareils",
    All_BATTERIES_TITLE: "Toutes les batteries",
    GOOD_BATTERIES_TITLE: "Bonnes batteries",
    REPLACE_BATTERIES_TITLE: "Batteries à remplacer",
    WARNING_BATTERIES_TITLE: "Batteries avec un avertissement",
    BATTERY_INFORMATION: "Informations sur la batterie",
  },
  batteryEssentialsDeviceView: {
    NO_SELECTION_BODY: "Sélectionnez un appareil de la liste ci-dessous pour en savoir plus sur son état et sa décharge moyenne.",
    NO_DEVICE_SELECTED: "Aucun appareil sélectionné",
    DEVICE_NOT_FOUND: "Aucun appareil trouvé",
    DEVICE_NOT_FOUND_BODY: "L'appareil que vous recherchez n'a pas été trouvé. Veuillez sélectionner un appareil de la liste ci-dessous.",
    GROUPS_FILTER_HEADER: "Groupes filtre",
    DEVICE_INFORMATION: "Informations sur l'appareil",
    HIGH_DISCHARGE: "Décharge elevée",
    LAST_KNOWN_BATTERY: "Dernière batterie connue",
    LAST_KNOWN_BATTERIES: "Dernières batteries connues",
    VIEW_IN_BATTERIES: "Voir dans batteries",
    AVERAGE_HOURLY_DISCHARGE: "Décharge horaire moyenne",
    DEVICE_HOURLY_DISCHARGE_STATEMENT: `Décharge moyenne de l'appareil par jour`,
    DEVICE_DISCHARGE: "Décharge",
    AVERAGE_DISCHARGE: "Décharge moyenne",
    All_DEVICES_TITLE: "Tous les dispositifs",
    GOOD_DEVICES_TITLE: "Bons appareils",
    WARNING_DEVICES_TITLE: "Appareils avec un avertissement"
  },
  estateOverview: {
    DEVICES_UNAFFECTED: "Appareils non affectés",
    ALERTING_PERCENTAGE: `Pourcentage d'alerte`,
    PERCENTAGE_AFFECTED: "Pourcentage affecté",
    DEVICES_AFFECTED: "Appareils concernés",
    PROBLEM_DEVICES: "Appareils problématiques",
    WARNING_DEVICES: `Appareils d'avertissement`,
    GOOD_DEVICES: "Bons appareils",
    TOTAL_DEVICES: "Total des appareils",
    DEFAULT_FILTER: `Tout type d'alerte`,
    ACCESS_POINT_FILTER: `Point d'accès n'appartenant pas à leur domicile`,
    TIME_ERROR_FILTER: "Erreur de temps",
    FILTER_TYPES: `Filtres de type d'alerte`,
    SUBTITLE: `Groupes et adresses à domicile avec "{{ text }}" alertes`,
    NO_ALERTS: "Aucun groupe ou lieu de résidence avec des alertes",
    HOME_LOCATIONS_AND_GROUPS: "adresses et groupes de domicile",
    NO_GROUP: '$t(translation:NO_GROUP)',
    HOME_LOCATION_GROUP: "$t(translation:GROUP) / $t(translation:HOME_LOCATION)",
    TYPE: "Type",
    FILTER_TOGGLE_SUBTITLE: "Montrer les filtres d'alerte",
  },

  alertOverview: {
    TITLE: 'Alertes ({{ alertCount }})',
    NO_ALERTS: `Il n'y a aucune alerte dans votre parc`,
    REBOOT_STATUS: 'ont un nombre élevé de redémarrages',
    TIME_ERR_STATUS: 'ont un erreur de temps',
    AVR_DISCHARGE_STATUS: 'ont une décharge horaire moyenne élevée',
    LOW_POWER_STATUS: `ont un nombre élevé d'événements de faible puissance`,
    LOCATION_DISTANCE_STATUS: `sont éloignés de leur endroit d'origine`,
    LOCATION_TIME_STATUS: `ont été loin de leur endroit d'origine pendant un certain temps`,
    DROP_STATUS: 'ont un nombre élevé de chutes',
    LOCATION_ACCESS_POINT_STATUS: `sont connectés à un point d'accès qui n'appartient pas à leur endroit d'origine`,
    IDLE_STATUS: 'ont été inactifs pendant un certain temps',
    OUT_OF_CONTACT_STATUS: 'ont été hors contact pendant un certain temps',
    OF_DEVICES: 'des appareils'
  },
  error: {
    SOMETHING_WRONG: `Désolé, quelque chose s'est mal passé.`,
    SOMETHING_WRONG_RETRY: '$t(error:SOMETHING_WRONG) Veuillez réessayer.',
    RELOAD_PAGE: 'Recharger la page'
  },
  settings: {
    SAVE_CHANGES: '$t(translation:SAVE_CHANGES)',
    CLEAR_CHANGES: 'Effacer les modifications',
    SAVE_SUCCESS: `L'opération a réussi`,
    SAVE_FAIL: `L'opération a échoué`,
    LAST_DAYS: '$t(timeState:IN_THE_LAST_M_other)',
    LAST_HOURS: '$t(timeState:IN_THE_LAST_F_other)',
    REBOOTS_TITLE: 'Redémarrages - Seuil global',
    REBOOTS_MESSAGE: 'redémarrage',
    REBOOTS_MESSAGE_other: 'redémarrages',
    REBOOTS_POPOVER: `Une alerte de redémarrage est générée lorsque le nombre de redémarrages de l'appareil est égal ou supérieur au seuil dans la période spécifiée`,
    OUT_OF_CONTACT_TITLE: 'Utilisation - Seuil hors contact',
    OUT_OF_CONTACT_MESSAGE: `L'appareil est hors de contact depuis`,
    OUT_OF_CONTACT_POPOVER: `L'alerte se produit lorsque le nombre de jours pendant lesquels l'appareil a été hors de contact dépasse le seuil`,
    OUT_OF_CONTACT_UNIT: 'jour',
    OUT_OF_CONTACT_UNIT_other: 'jours',
    USAGE_TITLE: `Utilisation - Seuil d'inactivité`,
    USAGE_MESSAGE: `L'appareil est inactif depuis`,
    USAGE_POPOVER: `L'alerte se produit lorsque le nombre de jours d'inactivité de l'appareil est égal ou supérieur au seuil`,
    USAGE_UNIT: 'jour',
    USAGE_UNIT_other: 'jours',
    LOW_POWER_EVENTS_TITLE: 'Événements de puissance faible - Seuil global',
    LOW_POWER_EVENTS_MESSAGE: 'événement de puissance faible',
    LOW_POWER_EVENTS_MESSAGE_other: 'événements de puissance faible',
    LOW_POWER_EVENTS_POPOVER: `Une alerte de puissance faible est générée lorsque le nombre d'événements de puissance faible est égal ou supérieur au seuil dans la période de temps spécifiée`,
    DROPS_TITLE: 'Chutes - Seuil global',
    DROPS_MESSAGE: 'chute',
    DROPS_MESSAGE_other: 'chutes',
    DROPS_POPOVER: `Une alerte de chute est générée lorsque le nombre de chutes d'appareils est égal ou supérieur au seuil dans la période spécifiée`,
    TIME_TITLE: `Emplacement - Seuil global d'enregistrement`,
    TIME_MESSAGE: `L'appareil n'est pas détecté à son endroit d'origine depuis`,
    TIME_POPOVER: `L'alerte se produit lorsque le temps pendant lequel l'appareil s'est éloigné de son domicile est égal ou supérieur au seuil`,
    TIME_UNIT: '$t(timeState:HOUR)',
    TIME_UNIT_other: '$t(timeState:HOUR_other)',
    DISTANCE_TITLE: 'Emplacement - Seuil global de distance',
    DISTANCE_MESSAGE: `L'appareil est à plus de`,
    DISTANCE_POPOVER: `L'alerte se produit lorsque la distance entre l'appareil et son emplacement d'origine est égale ou supérieure au seuil`,
    DISTANCE_UNIT: `$t(distance:LONG, { "context": "{{context}}", "count": {{count}} }) de son emplacement d'origine`,
    BATTERY_WARNING_HEADER: `Seuil d'avertissement`,
    BATTERY_REPLACE_HEADER: 'Seuil de remplacement',
    BATTERY_HEALTH_TITLE: 'Seuils de santé',
    BATTERY_HEALTH_POPOVER: `L'alerte se produit lorsque le pourcentage de «santé» est égale ou inférieure au seuil.`,
    BATTERY_HEALTH_WARNING_ERROR: `Le seuil d'avertissement doit se situer entre 2 et 99 l et il doit être supérieur au seuil de remplacement.`,
    BATTERY_HEALTH_REPLACE_ERROR: `Le seuil de remplacement doit se situer entre 1 et 98 et il doit être inférieur au seuil d'avertissement.`,
    BATTERY_HEALTH_ROW_TITLE: 'Toutes les batteries rapportant un état de santé',
    BATTERY_CYCLE_COUNT_TITLE: 'Seuils du nombre de cycles',
    BATTERY_CYCLE_COUNT_POPOVER: `L'alerte se produit lorsque le nombre de cycles est égal ou supérieur à chaque seuil. Il ne s'applique qu'aux batteries qui ne rapportent pas d'états de «santé».`,
    BATTERY_CYCLE_COUNT_WARNING_ERROR: `Le seuil d'avertissement doit se situer entre 1 et 9998 et doit être inférieur au seuil de remplacement.`,
    BATTERY_CYCLE_COUNT_REPLACE_ERROR: `Le seuil de remplacement doit se situer entre 2 et 9999 et doit être supérieur au seuil d'avertissement.`,
    BATTERY_CYCLE_COUNT_MANUFACTURER: '$t(translation:MANUFACTURER)',
    BATTERY_CYCLE_COUNT_PART: '$t(batteryEssentials:PART_NUMBER)',
    AVG_DISCHARGE_TITLE: `Seuil de la décharge moyenne de l'appareil`,
    AVG_DISCHARGE_MESSAGE: `Tous les dispositifs`,
    AVG_DISCHARGE_POPOVER: `L'alerte se produit lorsque la décharge moyenne est égal ou supérieur au seuil.`,
    AVG_DISCHARGE_ERROR: `Le seuil d'avertissement doit se situer entre 1 et 99`,
    PERIOD_IN_DAYS: 'Période (en jours)',
    THRESHOLD_WARNING: `Seuil d'avertissement`,
    THRESHOLD_PROBLEM: 'Seuil de problème',
    THRESHOLD_PERIOD_ERROR: 'Period Threshold should be between {{ min }} and {{ max }}',
    THRESHOLD_WARNING_ERROR: `Le seuil d'avertissement doit se situer entre {{ min }} et {{ max }} et il doit être inférieur au seuil de problème`,
    THRESHOLD_PROBLEM_ERROR: `Le seuil de problème doit se situer entre {{ min }} et {{ max }} et il doit être supérieur au seuil d'avertissement`,
    TAB_ALARMS: 'Alarmes',
    TAB_ALERTS: 'Alertes',
    TAB_BATTERY: 'Batterie',
    TAB_LOCATION: 'Emplacement',
    TAB_UTILISATION: 'Utilisation',
    TAB_UX: 'Expérience utilisateur',
    MAIN_TITLE: '$t(translation:SETTINGS)',
    ALERT_ACTIVE: "Alerte active",
    BATTERY_ALERTS: "Alertes de batterie",
    HOME_LOCATION_WARNING_ALERT_TOOLTIP_TEXT: "Alerte d'avertissement générée lorsqu'un appareil est connecté à un réseau connu qui n'appartient pas à son endroit d'origine attribué.",
    HOME_LOCATION_PROBLEM_ALERT_TOOLTIP_TEXT: "Alerte de problème générée lorsqu'un appareil est connecté à un réseau inconnu.",
    HOME_LOCATION_NETWORK_ALERT: "Alerte de réseau de l'endroit d'origine",
    HOME_LOCATION_NETWORK_ALERT_WARNING_TEXT: "L'appareil est connecté à un réseau connu, qui n'appertient pas à son propre endroit d'origine.",
    HOME_LOCATION_NETWORK_ALERT_PROBLEM_TEXT: "L'appareil est connecté à un réseau inconnu.",
    PROBLEM_ALERT: "Alerte de problème:",
    WARNING_ALERT: "Alerte d'avertissement:",
    DAILY_RUN_TIME: "Temps d'exécution quotidien:",
    ANOMALY_DETECTION: "Détection des Anomalies",
    LOCATION_INFORMATION_TITLE: "Collecte d'informations de localisation et création de rapports",
    LOCATION_WINDOWS_ENABLED: "Activé sur les appareils Windows",
    LOCATION_ANDROID_ENABLED: "Activé sur les appareils Android",
    DROPS_ENABLED_TITLE: "Détection des chutes",
    DROPS_ANDROID_ENABLED: "Activé sur les appareils Android",
    ENABLE_ANOMALY_DETECTION: `Activer « Détection des Anomalies »`,
    SELECT_HOUR_LABEL: "Sélectionnez l'heure",
    SELECT_AM_OR_PM_LABEL: "Sélectionnez AM ou PM",
    SELECT_TIME_ZONE_LABEL: "Sélectionnez le fuseau horaire",
    PLATFORM_DEVICE_IDENTIFIER_TITLE: "Identificateur de périphérique principal",
    PLATFORM_DEVICE_IDENTIFIER_INFORMATION: "Sélectionnez la valeur principale par laquelle vous identifiez les appareils de votre parc d'appareils.",
    PLATFORM: "Plateforme",
    LOCATION_INFO_QUESTION: 'Confirmation des Paramètres de Localisation',
    LOCATION_INFO_TURNING_OFF: "Lorsque vous désactivez la collecte et la création de rapports de données de localisation, les données de localisation historiques déjà collectées ne seront pas supprimées automatiquement. Veuillez contacter le support B2M si vous souhaitez que les données de localisation historiques soient supprimées.",
    LOCATION_INFO_TURNING_ON: "En activant la collecte et la communication des données de localisation des appareils, vous consentez au traitement des données de localisation des appareils dans votre monde. Veuillez vous assurer que vous êtes autorisé à fournir ce consentement au nom de votre organisation et que l'enregistrement des données de localisation des appareils est légalement autorisé dans les juridictions et les appareils couverts par ce service. Notez également que si vous désactivez ultérieurement la collecte des données de localisation, les données de localisation historiques déjà collectées ne seront pas supprimées automatiquement. Veuillez contacter le support B2M si vous souhaitez que les données de localisation historiques soient supprimées.",
    MOBILE_SIGNAL_REPORTING_TITLE: "Collecte et reporting des niveaux de signaux mobiles.",
    MOBILE_SIGNAL_REPORTING_ANDROID: "Activé pour les appareils Android",
    MOBILE_SIGNAL_REPORTING_WINDOWS: "Activé pour Windows Appareils",
  },

  maps: {
    VERY_POOR_NO_SERVICE_TAB: 'Très mauvais/pas de service',
    VERY_POOR_NO_SERVICE_HEADING: "Zones de très mauvais signal ou d'absence de service",
    VERY_POOR_NO_SERVICE_SUBHEADING: 'Identifiez les zones où les appareils sont plus susceptibles de recevoir un signal très faible ou de ne recevoir aucun service',
    VERY_POOR_NO_SERVICE_INTENSITY: 'Lectures très mauvaises/pas de service',
    LOCATION: 'Emplacement',
    TOTAL_COUNT: 'Valeurs totales',
    CONFIG_GRID_SHOWN: 'Grille affichée',
    CONFIG_GRAYSCALE: 'Niveaux de gris',
    CONFIG_LEGEND: 'Légende',
    CONFIG_TOOLTIP: 'Info-bulle au survol',
    CONFIG_DISCRETE: 'Afficher les groupes',
    NO_SERVICE_HEADING: 'Zones sans service',
    NO_SERVICE_TAB: 'Pas de service',
    NO_SERVICE_SUBHEADING: 'Identifier les zones où les appareils ont été plus susceptibles de ne pas avoir de service',
    NO_SERVICE_INTENSITY: 'Valeurs de pas avoir de service',
    COVERAGE_HEADING: "Lectures de couverture",
    COVERAGE_INTENSITY: "Valeurs de couverture",
    COVERAGE_SUBHEADING: "Couverture réseau par fournisseur SIM et type de réseau",
    COVERAGE_TAB: "Couverture",
    SIM_PROVIDERS: "Fournisseurs de cartes SIM",
    ALL_SIM_PROVIDERS: "Tous les fournisseurs de cartes SIM",
    DEVICES_HEADING: 'TODO Devices',
    LOCATION_SUBHEADING: '$t(maps:LOCATION)',
    LOCATION_TAB: '$t(maps:LOCATION)',
    CLUSTER_PROMPT: 'Sélectionnez un cluster pour afficher les périphériques de ce cluster.',
    LOCATION_COLLECTION_INFO: `<0>{{ totalCount }}</0> appareil correspond au filtre, <2>{{ withLocationCount }}</2> ont des informations de localisation et peuvent être affichés sur la carte`,

  },

  alarmThresholds: {
    DEVICE_STATUS_ALARM: `Tous les types d'alerte`,
    DEVICE_LOCATION_TIME_STATUS_ALARM: `$t(filters:DEVICE_LOCATION_TIME_STATUS_RED)`,
    DEVICE_TIME_ERROR_STATUS_ALARM: `$t(filters:DEVICE_TIME_ERROR_STATUS_RED)`,
    DEVICE_LOCATION_DISTANCE_STATUS_ALARM: `$t(filters:DEVICE_LOCATION_DISTANCE_STATUS_RED)`,
    AVERAGE_DISCHARGE_STATUS_ALARM: `$t(filters:AVERAGE_DISCHARGE_STATUS_YELLOW)`,
    DEVICE_USER_EXP_DROP_STATUS_ALARM: `$t(filters:DEVICE_USER_EXPERIENCE_DROP_STATUS_RED)`,
    DEVICE_USER_EXP_LOW_POWER_STATUS_ALARM: `$t(filters:DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_RED)`,
    DEVICE_USER_EXP_REBOOT_STATUS_ALARM: `$t(filters:DEVICE_USER_EXPERIENCE_REBOOT_STATUS_RED)`,
    DEVICE_IDLE_STATUS_ALARM: `$t(filters:DEVICE_UTILISATION_IDLE_STATUS_RED)`,
    DEVICE_OUT_OF_CONTACT_STATUS_ALARM: `$t(filters:DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_RED)`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS_ALARM: `$t(filters:DEVICE_LOCATION_ACCESS_POINT_STATUS_RED)`,
    HEADER: 'Configuration des alarmes',
    HEADER_TOOLTIP: `Les alarmes se déclenchent quand le pourcentage d'appareils en état d'alerte dans un groupe ou endroit d'origine est égal ou supérieur au seuil configuré. <1>Les alarmes qui alertent actuellement sont affichées sur la page 'Aperçu' dans le tableau 'Décompte des alertes du parc'.</1>`,
    MAJOR_ALARM_THRESHOLD: `Seuil sérieux d'alarme`,
    CRITICAL_ALARM_THRESHOLD: `Seuil urgent d'alarme`,
    MAJOR_ALARM_THRESHOLD_TOOLTIP: `Les alarmes sérieuses sont le primer niveau d'alarme. Ces alarmes sont affichées comme reflets jaunes dans le tableau 'Décompte des alertes du parc' de la page 'Aperçu'.`,
    CRITICAL_ALARM_THRESHOLD_TOOLTIP: `L'alarme urgente est la plus sévère. Ces alarmes sont affichées comme reflets rouges dans le tableau 'Décompte des alertes du parc' de la page 'Aperçu'.`,
    ALERT_TYPE: `Type d'alerte`,
    ALARM_ACTIVE: 'Alarme active',
    CRITICAL_THRESHOLD_VALIDATION_ERROR: `Le seuil urgent d'alarme doit se situer entre 2 et {{ max }} et il doit être supérieur au seuil sérieux d'alarme`,
    MAJOR_THRESHOLD_VALIDATION_ERROR: `Le seuil sérieux d'alarme doit se situer entre 1 et {{ max }} et il doit être inférieur au seuil urgent d'alarme`
  },

  userManagement: {
    USER_MANAGEMENT: "Gestion des utilisateurs",
    USER: "utilisateur",
    USER_other: "utilisateurs",
    USERS_LIST_TITLE: "Utilisateurs",
    EMAIL_ADDRESS: "Adresse e-mail",
    ROLE: "Rôle",
    CUSTOM_VIEW: "Aperçu personnalisé",
    CUSTOM_VIEW_LIST_TOOLTIP: "On peut limiter les appareils qu'un utilisateur peut voir en selectionnant l'endroit d'origine, le groupe ou la zone auxquels vous voulez lui donner accès pendant sa création ou modification.",
    EDIT_CUSTOM_VIEW_TOOLTIP: "On peut limiter les appareils que cet utilisateur peut voir en selectionnant l'endroit d'origine, le groupe ou la zone auxquels vous voulez lui donner accès.",
    CUSTOM_VIEW_LIST_ZONE: "Zone: {{ zone }}",
    CUSTOM_VIEW_LIST_GROUPS_HOME_LOCATIONS: "Endroit d'origine: <1>{{ homeLocation }}</1>, Groupe: <3>{{ group }}</3>",
    ALL_HOME_LOCATIONS: "Tous les endroits d'origine",
    ALL_GROUPS: "Tous les groupes",
    PRODUCT_ACCESS: "Accès aux produits",
    WORLD_ACCESS: "Accès aux mondes",
    VIEWER: "Spectateur",
    EDITOR: "Éditeur",
    MANAGER: "Gerant",
    ADMIN: "Administrateur",
    ALL_DEVICES: "$t(filters:ALL_DEVICES)",
    ALL_WORLDS: "Tous les mondes",
    EDIT_USER: "Éditer l'utilisateur",
    EDIT_USER_DESCRIPTION: "Éditer les informations et autorisations de cet utilisateur",
    ADD_USER: "Nouvel utilisateur",
    ADD_USER_DESCRIPTION: "Ajouter un nouvel utilisateur et configurer ses autorisations.",
    EDIT_DENIED_HEADER: "L'utilisateur selectionné ne peut pas être édité",
    EDIT_DENIED_SUBHEADER: "Il n'est pas possible d'éditer votre propre compte.",
    DELETE_DENIED_HEADER: "L'utilisateur selectionné ne peut pas être supprimé",
    DELETE_DENIED_HEADER_other: "Les utilisateur selectionnés ne peuvent pas être supprimés",
    DELETE_DENIED_SUBHEADER: "Il n'est pas possible de supprimer votre propre compte.",
    RETURN_TO_LIST: "Revenir à la liste des utilisateurs",
    TOOLTIP_VIEWER_TEXT: "l'utilisateur ne peut voir que tous les actifs de la succession.",
    TOOLTIP_EDITOR_TEXT: "l'utilisateur peut afficher tous les actifs du domaine, modifier l'appartenance au groupe, l'emplacement du domicile et l'étiquette d'actif d'un appareil, retirer un appareil du domaine et retirer une batterie du domaine.",
    TOOLTIP_MANAGER_TEXT: "l'utilisateur a les mêmes capacités que le rôle d'éditeur et peut également configurer les paramètres d'alerte et d'alarmes mondiales et gérer le tableau des emplacements de domicile.",
    TOOLTIP_ADMIN_TEXT: "l'utilisateur a les mêmes capacités que le rôle de gestionnaire et peut également créer, modifier et supprimer des comptes d'utilisateurs.",
    TOOLTIP_WORLD_ACCESS: "On peut fournir accès à plusieurs mondes aux utilisateurs qui ne sont pas les administrateurs, si nécessaire. Les administrateurs ont toujours accès à tous les mondes.",
    EMAIL_EXISTS_ERROR: "Un utilisateur avec cette adresse e-mail existe déjà.",
    INVALID_EMAIL_ERROR: "Veuillez saisir une adresse e-mail valide.",
    USER_NOT_FOUND: "Cet utilisateur n'existe pas",
  },

  filters: {
    SELECT: 'Sélectionnez',
    get SELECT_HOME_LOCATION() { return `${this.SELECT} l'${frTranslations.translation.HOME_LOCATION.toLowerCase()}`; },
    get SELECT_GROUP() { return `${this.SELECT} le ${frTranslations.translation.GROUP.toLowerCase()}`; },
    get SELECT_ZONE() { return `${this.SELECT} la ${frTranslations.zones.ZONE.toLowerCase()}`; },
    ALERT_TYPES_FILTER: `Types d'alertes`,
    ALERT_TYPES_FILTER_other: `$t(filters:ALERT_TYPES_FILTER)`,
    ALERT_LEVEL_COL: `Niveau d'alerte`,
    AVERAGE_DISCHARGE_STATUS_GROUP: `Décharge moyenne`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS_GROUP: `Réseau`,
    DEVICE_LOCATION_DISTANCE_STATUS_GROUP: `$t(translation:LOCATION) (distance)`,
    DEVICE_LOCATION_TIME_STATUS_GROUP: `$t(translation:LOCATION) (temps)`,
    DEVICE_TIME_ERROR_STATUS_GROUP: `$t(editEvents:TIME_ERROR)`,
    DEVICE_USER_EXPERIENCE_DROP_STATUS_GROUP: `$t(performance:DROPS)`,
    DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_GROUP: `Faible puissance`,
    DEVICE_USER_EXPERIENCE_REBOOT_STATUS_GROUP: `$t(performance:REBOOTS)`,
    DEVICE_UTILISATION_IDLE_STATUS_GROUP: `$t(translation:IDLE)`,
    DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_GROUP: `Hors contact`,
    AVERAGE_DISCHARGE_STATUS_YELLOW: `Décharge horaire moyenne élevée`,
    AVERAGE_DISCHARGE_STATUS_GREEN: `Aucune alerte de décharge horaire moyenne`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS_RED: `Connecté à un réseau incconu`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS_YELLOW: `Connecté à un réseau connu qui n'appartient pas à son endroit d'origine`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS_GREEN: `Aucune alerte de réseau`,
    DEVICE_LOCATION_DISTANCE_STATUS_RED: `Éloigné de son endroit d'origine`,
    DEVICE_LOCATION_DISTANCE_STATUS_YELLOW: `$t(filters:DEVICE_LOCATION_DISTANCE_STATUS_RED)`,
    DEVICE_LOCATION_DISTANCE_STATUS_GREEN: `Aucune alerte de localisation (distance)`,
    DEVICE_LOCATION_TIME_STATUS_RED: `Éloigné de son endroit d'origine pendant un certain temps`,
    DEVICE_LOCATION_TIME_STATUS_YELLOW: `$t(filters:DEVICE_LOCATION_TIME_STATUS_RED)`,
    DEVICE_LOCATION_TIME_STATUS_GREEN: `Sans alerte de localisation (temps)`,
    DEVICE_TIME_ERROR_STATUS_RED: `Actuellement avec une erreur de temps`,
    DEVICE_TIME_ERROR_STATUS_GREEN: `Aucune alerte d'erreur de temps`,
    DEVICE_USER_EXPERIENCE_DROP_STATUS_RED: `Nombre élevé de chutes`,
    DEVICE_USER_EXPERIENCE_DROP_STATUS_YELLOW: `$t(filters:DEVICE_USER_EXPERIENCE_DROP_STATUS_RED)`,
    DEVICE_USER_EXPERIENCE_DROP_STATUS_GREEN: `Aucune alerte de chute`,
    DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_RED: `Nombre élevé d'événements de faible puissance`,
    DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_YELLOW: `$t(filters:DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_RED)`,
    DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_GREEN: `Aucune alerte de faible puissance`,
    DEVICE_USER_EXPERIENCE_REBOOT_STATUS_RED: `Nombre élevé de redémarrages`,
    DEVICE_USER_EXPERIENCE_REBOOT_STATUS_YELLOW: `$t(filters:DEVICE_USER_EXPERIENCE_REBOOT_STATUS_RED)`,
    DEVICE_USER_EXPERIENCE_REBOOT_STATUS_GREEN: `Aucune alerte de redémarrage`,
    DEVICE_UTILISATION_IDLE_STATUS_RED: `Inactif pendant un certain temps`,
    DEVICE_UTILISATION_IDLE_STATUS_YELLOW: `$t(filters:DEVICE_UTILISATION_IDLE_STATUS_RED)`,
    DEVICE_UTILISATION_IDLE_STATUS_GREEN: `Aucune alerte d'inactivité`,
    DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_RED: `Hors contact pendant un certain temps`,
    DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_YELLOW: `$t(filters:DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_RED)`,
    DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_GREEN: `Aucune alerte de manque de contact`,
    ALL_DEVICES: `Tous les dispositifs`,
    FILTER_BY: `Filtrer par:`,
    TIME_PERIOD: `Période de temps:`,
    SELECT_TIME_PERIOD: `Période de temps`,
  },

  homeLocations: {
    HOME_LOCATION_MANAGEMENT: "Gestions des $t(translation:HOME_LOCATION)",
    UPDATE_TITLE: "Modifier l'endroit d'origine",
    CREATE_TITLE: "Nouveau endroit d'origine",
    DELETE_TITLE: "Supprimer l'endroit d'origine",
    DELETE_TITLE_other: "Supprimer les endroits d'origine",
    UPDATE_TITLE_TOOLTIP: "Modifiez les détails de votre endroit d'origine. Les modifications de la latitude, de la longitude et du rayon peuvent affecter les alertes des appareils basés sur la position actuelle.",
    CREATE_TITLE_TOOLTIP: "Créez un emplacement géographique d'origine pour vos appareils qui peut être utilisé lors de la configuration d'alertes d'appareils basées sur l'emplacement.",
    DETAILS_TITLE: "Des détails",
    THRESHOLDS_TOOLTIP: "Alerte générée lorsque la distance entre l'appareil et son endroit d'origine est égale ou supérieure au seuil.",
    THRESHOLDS_TITLE: "Seuils d'alerte de distance",
    GLOBAL_THRESHOLDS: "Utiliser des seuils globaux",
    CUSTOM_THRESHOLDS: "Seuils personnalisés",
    LATITUDE: "Latitude",
    LONGITUDE: "Longitude",
    ADDRESS_LINE: "Adresse {{number}}",
    LOCALITY: "Localité",
    POSTCODE: "ZIP / code postal",
    COUNTRY: "Pays",
    RADIUS: "Rayon",
    RADIUS_WITH_UNIT: `Rayon ($t(distance:SHORT_{{context}}_other))`,
    WARNING_THRESHOLD: "Seuil d'avertissement",
    PROBLEM_THRESHOLD: "Seuil de problème",
    WARNING_THRESHOLD_WITH_UNIT: `Seuil d'avertissement ($t(distance:LONG_{{context}}_other))`,
    PROBLEM_THRESHOLD_WITH_UNIT: `Seuil de problème ($t(distance:LONG_{{context}}_other))`,
    GLOBAL: "Global",
    LATITUDE_VALIDATION_ERROR_RANGE: "La latitude doit être comprise entre -90 et 90",
    LONGITUDE_VALIDATION_ERROR_RANGE: "La longitude doit être comprise entre -180 et 180",
    RADIUS_VALIDATION_ERROR_RANGE: "Le rayon doit être supérieur à 0",
    WARNING_THRESHOLD_VALIDATION_ERROR_RANGE: "Le seuil d'avertissement doit être supérieur à 0",
    WARNING_THRESHOLD_VALIDATION_ERROR_RELATIVE: "Le seuil d'avertissement doit être inférieur au seuil de problème",
    WARNING_THRESHOLD_VALIDATION_ERROR_MAX: "Le seuil d'avertissement doit être inférieur ou égal à {{ max }}",
    PROBLEM_THRESHOLD_VALIDATION_ERROR_RANGE: "Le seuil du problème doit être supérieur à 0",
    PROBLEM_THRESHOLD_VALIDATION_ERROR_RELATIVE: "Le seuil du problème doit être supérieur au seuil d'avertissement",
    PROBLEM_THRESHOLD_VALIDATION_ERROR_MAX: "Le seuil du problème doit être inférieur ou égal à {{ max }}",
    ERROR_ALREADY_EXISTS: "Un endroit d'origine avec ce nom existe déjà."
  },

  forms: {
    REQUIRED_FIELDS: `Champs obligatoires`
  },

  bulkActions: {
    BULK_ACTIONS: "Actions en masse",
    HOME_LOCATION_CHANGE: "Changement d'endroit d'origine",
    SELECT_HOME_LOCATION: "Sélectionnez un endroit d'origine",
    MOVE: "Déplacer",
    MOVE_DEVICES_TEXT: "Déplacer tous les appareils dans",
    MOVE_STATUS_TEXT: "Mise à jour de l'endroit d'origine. S'il vous plaît, attendez.",
    MOVE_CONFIRMATION_TEXT: "L'endroit d'origine du <1>{{count}}</1> appareil de '{{from}}' sera remplacé par '{{to}}'.",
    MOVE_CONFIRMATION_TEXT_other: "L'endroit d'origine de tous les <1>{{count}} appareils</1> de '{{from}}' sera remplacé par '{{to}}'.",
    MOVE_VALIDATION_ERR_TEXT: "Veuillez sélectionner un autre endroit d'origine.",
    MOVE_SUCCESS_TEXT: "Les appareils ont été déplacés vers '{{location}}' avec succès.",
    MOVE_ERROR_TEXT: "Une erreur s'est produite, il est possible que certains appareils n'aient pas été déplacés. Veuillez réessayer."
  },

  zones: {
    MANAGE_ZONES: "Gérer les zones",
    ZONE: "Zone",
    ZONES: "Zones",
    ZONE_other: "Zones",
    AND_GROUPS: `AND $t(translation:GROUPS)`,
    AND_HOMELOCATIONS: 'AND $t(translation:HOME_LOCATION_other)',
    NEW_ZONE: "Nouvelle zone",
    EDIT_ZONE: "Zone d'édition",
    ZONE_NAME: "Nom de zone",
    GROUPS1_TAB_HEADER: "Sélectionnez le(s) groupe(s) d'appareils à ajouter à la zone.",
    GROUPS2_TAB_HEADER: "Ajoutez des groupes, à la zone, auxquels les appareils doivent AUSSI appartenir.",
    HOMELOCATIONS_TAB_HEADER: "Sélectionnez le(s) lieu(x) de domicile à ajouter à la zone.",
    ZONE_TAB_DESCRIPTION: "Veuillez attribuer au moins un groupe ou un endroit d'origine pour créer cette zone.",
    ZONE_OPTIONS_TEXT_GROUPS1: "Tout appareil appartenant au(x) groupe(s):",
    ZONE_OPTIONS_TEXT_GROUPS2: "ET qui est également dans le(s) groupe(s):",
    ZONE_OPTIONS_TEXT_HOMELOCATIONS: "ET qui se trouve également dans le(s) lieu(x) d'origine:",
    ZONE_OPTIONS_TEXT_HOMELOCATIONS_without_groups: `Tout appareil qui se trouve à l'emplacement ou aux emplacements d'origine:`,
    ZONE_SUMMARY: "Résumé de la zone",
    NAME_VALIDATION_ERROR: "Nom invalide, veuillez utiliser uniquement des caractères alphanumériques sans espaces.",
    NAME_EXISTS_ERROR: "Une zone portant ce nom existe déjà. Veuillez saisir un autre nom.",
    SAVE_FAILED_ERROR: "L'action a échoué pour cette zone, veuillez réessayer.",
    OPTION_SELECTION_ERROR: "Les options sélectionnées ne doivent pas dépasser plus de 20.",
    EDIT_ZONE_NAVIGATION_TEXT: "Sélectionnez une zone dans la liste à modifier",
    SUBHEADER_SINGLE: "« {{ zone }} » sera supprimé.",
    SUBHEADER: "{{ count }} zone sera supprimé.",
    SUBHEADER_other: "{{ count }} zones seront supprimés.",
    MESSAGE: "Vous ne pourrez plus attribuer de groupes ou de domiciles à cette zone.",
    MESSAGE_other: "Vous ne pourrez plus attribuer de groupes ou d'emplacements d'origine à ces zones.",
    CONFIRM_DELETE: "Oui, supprimer cet zone",
    CONFIRM_DELETE_other: "Oui, supprimer ces zones",
    FAILURE_HEADER: "On ne peut pas supprimer la zone",
    FAILURE_HEADER_other: "On ne peut pas supprimer les zones",
    FAILURE_HEADER_USERS: "La zone suivante ne peut pas être supprimée car des utilisateurs lui sont affectés:",
    FAILURE_HEADER_USERS_other: "Les zones suivantes ne peuvent pas être supprimées car des utilisateurs leur sont affectés:",
    FAILURE_FOOTER_USERS: "Vous pouvez modifier les zones de ces utilisateurs individuellement.",
    ZONE_NOT_FOUND: "La zone n'existe pas"
  },

  deviceNetwork: {
    NETWORK: 'Réseau',
    SIGNAL_TAB_TITLE: `Signal`,
    '2G': '2G',
    '3G': '3G',
    '4G': '4G',
    '5G': '5G',
    SIGNAL_LEVEL: 'Niveau de signal',
    CHANGE_SIGNAL_LEVEL: 'Changer le niveau de signal',
    SIGNAL: 'Signal',
    'LAT/LONG': 'Lat/Long',
    OPERATOR: 'Opérateur',
    VERY_POOR: 'Très pauvre',
    POOR: 'Mauvais',
    MODERATE: 'Moyen',
    FAIR: 'Assez fort',
    GOOD: 'Fort',
    GREAT: 'Très fort',
    BAR_COUNT: '{{ count }} barre',
    BAR_COUNT_other: '{{ count }} barres',
    NO_SERVICE: 'Sans service',
    EDIT_EVENTS_MODAL_HEADER: `Modifiez les types d'événements affichés sur les graphiques de niveau de signal.`
  },

  deviceCoverage: {
    DEVICE_COVERAGE_TAB_TITLE: 'Service'
  },

  aboutPopup: {
    CONTENT_HEADER: 'Elemez de B2M Solutions',
    CONTENT_HEADER_PANASONIC: 'Panasonic Smart Essentials est alimenté par Elemez de B2M Solutions',
    COPYRIGHT: "Droits d'auteur \u00A9 2015 - {{ currentYear }} Biz2mobile Ltd. Tous droits réservés.",
    HEADER: "À propos Elemez",
    HEADER_PANASONIC: "À propos Panasonic Smart Essentials",
    LICENSE_HEADER: "Logiciels open source"
  }
};
