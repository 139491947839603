import * as React from "react";
import { LatLngBounds } from "leaflet";

import { degreesToRads } from "../utils";
import { DeviceClusterResponse } from "../../../services/core/maps";

export const useClusterProps = (bounds: LatLngBounds, data?: DeviceClusterResponse) => {
  return React.useMemo(() => {
    const groundResolution = 40075016.686;

    if (data) {
      return data.clusters
        .filter(dataPoint =>
          dataPoint.lat >= bounds.getSouth() && dataPoint.lat <= bounds.getNorth() &&
            dataPoint.lon >= bounds.getWest() && dataPoint.lon <= bounds.getEast()
        )
        .map(({ lat, lon: lng, keys, count }) => {
          const radius = (groundResolution / Math.pow(2, data.precision)) * Math.cos(degreesToRads(lat)) * Math.sqrt(keys.length);
          return {
            lat,
            lng,
            radius,
            count
          };
        });
    }
    return [];
  }, [bounds, data]);
};
